import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from 'chart.js';
import { getServersOnline } from '../../api/landingService';
import { useEffect, useState } from 'react';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

const getLocalizedWeekdays = () => {
  const days = [];
  const today = new Date();
  const formatter = new Intl.DateTimeFormat('default', { weekday: 'short' }); // Локалізація коротких назв днів тижня

  for (let i = 6; i >= 0; i--) {
    const day = new Date(today);
    day.setDate(today.getDate() - i);
    days.push(formatter.format(day));
  }

  return days;
};

const options = {
  scales: {
    x: {
      ticks: {
        color: '#ffffff',
      },
    },
    y: {
      ticks: {
        color: '#ffffff',
      },
    },
  },
  plugins: {
    legend: {
      labels: {
        color: '#ffffff',
      },
    },
  },
};

function ServerChart() {
  const [serversData, setServersData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchServersOnline = async () => {
      setLoading(true);
      try {
        const result = await getServersOnline();
        setServersData(result.servers || []); // Беремо лише `servers` з об'єкта
      } catch (err) {
        console.error(err.message || 'Failed to fetch fetchServersOnline');
      } finally {
        setLoading(false);
      }
    };

    fetchServersOnline();
  }, []);

  if (loading) {
    return <p className="text-center text-white">Завантаження даних...</p>;
  }

  if (serversData.length === 0) {
    return <p className="text-center text-white">Дані недоступні</p>;
  }

  const data = {
    labels: getLocalizedWeekdays(), // Дні тижня
    datasets: serversData.map((server, index) => ({
      label: server.name,
      borderColor: ['#f97316', '#db2777','#ffffff' ][index],
      backgroundColor: [
        'rgba(249,115,22,0.25)',
        'rgba(219,39,119,0.25)',
        'rgba(255,255,255,0.25)',
      ][index],
      data: server.online_for_week,
      fill: true,
      tension: 0.3,
    })),
  };

  return <Line data={data} options={options} />;
}

export default ServerChart;
