import React, { useEffect } from "react";
// import { useTranslation } from "react-i18next";
import { useCart } from "../../context/CartContext";
import { MdShoppingCart, MdOutlineShoppingCart } from "react-icons/md";
import { useState } from "react";
import { getProducts } from "../../api/shopsService";
import NeedRegPopup from "../needRegPopup/NeedRegPopup";
import { usePopup } from "../../context/PopupContext";

const Plugin = ({ userData }) => {
  const lang = localStorage.getItem("i18nextLng") || "en";
  // const { t } = useTranslation();
  const [products, setProducts] = useState();
  const { addToCart, removeFromCart, isInCart } = useCart();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { isPopupOpen, closePopup } = usePopup();
  const { openPopup } = usePopup();

  useEffect(() => {
    const fetchProducts = async () => {
      setLoading(true);
      setError(null);

      try {
        const result = await getProducts("PLUGIN"); // Викликаємо функцію з передачею категорії
        setProducts(result);
      } catch (err) {
        setError(err.message || "Failed to fetch products");
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    fetchProducts(); // Викликаємо функцію при завантаженні компонента
  }, []);

  const handleCartClick = (product) => {
    if (userData) {
      if (isInCart(product.id)) {
        removeFromCart(product);
      } else {
        addToCart(product); // Передаємо повний об'єкт продукту
      }
    } else {
      openPopup(); // Викликаємо попап, якщо користувач не авторизований
    }
  };

  const pluginCard = (product) => {
    return (
      <div className="flex flex-1 flex-col justify-between" key={product.id}>
        <p className="text-sm text-start mb-6 text-gray-100">
          {product.description[lang]}
        </p>
        <button
          onClick={() => handleCartClick(product)} // Передаємо функцію-обгортку
          className="flex flex-row sm:flex-col md:flex-row items-center md:text-lg lg:text-xl text-center rounded-md bg-orange-500 hover:bg-orange-600 transition-all duration-300 ease-in-out"
        >
          <p className="bg-orange-900 text-orange-500 px-4 py-2 md:px-4 lg:px-3 xl:px-4 md:py-4 rounded-s-md sm:rounded-t-md sm:rounded-b-none md:rounded-s-md md:rounded-tr-none sm:w-full md:w-auto">
            {isInCart(product.id) ? (
              <MdShoppingCart className="inline w-6 h-6 me-3 -mt-1 fill-current" />
            ) : (
              <MdOutlineShoppingCart className="inline w-6 h-6 me-3 -mt-1" />
            )}
            <span className="text-xs text-orange-600 line-through">
              ${product.price}
            </span>{" "}
            ${(product.price - product.price * product.discount).toFixed(2)}
          </p>
          <span className="flex-1 px-4 py-2 md:px-4 lg:px-3 xl:px-4 md:py-4 rounded-e-md">
            {product.title[lang]}
          </span>
        </button>
      </div>
    );
  };

  return (
    <div className="">
      <div
        className="bg-cover bg-center -mx-4 p-4 md:p-6 lg:p-6 xl:p-20 h-dvh -mt-16 flex items-center"
        style={{ backgroundImage: `url(/plugin-bg.png)` }}
      >
        <div className="text-center flex flex-col justify-center w-full xl:w-3/4 mx-auto">
          <p className="text-4xl md:text-7xl font-bold mb-8">Plugins</p>
          {/* <p className="text-sm text-neutral-300 mb-8 leading-normal">
            MEE6 is a complete Discord bot, easy-to-use, that millions of
            Discord servers worldwide trust to manage, entertain, and grow their
            community.
          </p> */}
          <div className="flex flex-col justify-between sm:justify-start sm:flex-row gap-4 gap-y-8 xl:gap-6">
            {loading
              ? Array.from({ length: 2 }).map((_, index) => (
                  <div key={index} className="flex flex-1 flex-col justify-between gap-y-2">
                    <div className="h-3 w-5/6 bg-white rounded-md animate-pulse"></div>
                    <div className="h-3 w-4/5 bg-white rounded-md animate-pulse"></div>
                    <div className="h-3 w-full bg-white rounded-md animate-pulse"></div>
                    <div className="h-3 w-full bg-white rounded-md animate-pulse  mb-5"></div>
                    <div className="flex md:text-lg lg:text-xl text-center rounded-md bg-orange-500 hover:bg-orange-600 transition-all duration-300 ease-in-out">
                      <div className="bg-orange-900 text-orange-500 px-4 py-2 md:px-4 lg:px-3 xl:px-4 md:py-4 rounded-s-md flex">
                        <MdOutlineShoppingCart className="inline w-6 h-6 me-3" />
                        <div className="h-6 w-28 bg-orange-600 animate-pulse rounded-md"></div>
                      </div>
                      <div className="flex-1 px-4 py-2 md:px-4 lg:px-3 xl:px-4 md:py-4 rounded-e-md">
                      <div className="h-6 w-full me-2 bg-white animate-pulse rounded-md"></div>
                      </div>
                    </div>
                  </div>
                ))
              : products && products.map((product) => pluginCard(product))}
          </div>
        </div>
      </div>
      <h1 className="text-3xl sm:text-4xl font-bold text-center mb-6">
        Easy <span className="text-orange-500">Setup</span> &{" "}
        <span className="text-orange-500">Configuration</span>
      </h1>
      <div className="flex flex-col lg:flex-row items-center justify-center px-10 text-white gap-x-4">
        <div className="max-w-4xl w-full flex-1">
          <div className="text-lg sm:text-2xl leading-relaxed text-gray-200 flex flex-col justify-between gap-y-10">
            <p className="leading-loose">
              The process has been made{" "}
              <span className="text-orange-500 font-semibold">easier</span> for
              people with no experience can
              <span className="text-orange-500 font-semibold"> setup it</span>{" "}
              <span className="text-2xl">🛠️</span> without too much hastle.
            </p>
            <p className="leading-loose">
              Also our{" "}
              <span className="text-orange-500 font-semibold">24/7 Support</span>{" "}
              <span className="text-2xl">⏰</span> will assist you for the
              installation and{" "}
              <span className="text-orange-500 font-semibold">help you</span> to
              configure the plugin if something is not clarified in
              <span className="text-orange-500 font-semibold"> our wiki page</span>.
            </p>
          </div>
        </div>
        <div className="flex-1 flex flex-col sm:flex-row items-center justify-center gap-4">
          <div className="w-full">
            <img
              src="bot1.webp"
              alt="File structure example"
              className="w-full h-auto rounded-lg"
            />
          </div>
          {/* <div className="bg-neutral-900 p-4 rounded-lg shadow-lg max-w-xs w-full">
        <img src="path/to/your/image2.png" alt="Code example 1" className="w-full h-auto rounded-lg">
      </div>
      <div className="bg-neutral-900 p-4 rounded-lg shadow-lg max-w-xs w-full">
        <img src="path/to/your/image3.png" alt="Code example 2" className="w-full h-auto rounded-lg">
      </div> */}
        </div>
      </div>
      <div
        className="-mx-4 mt-10 p-24 bg-center bg-cover"
        style={{ backgroundImage: `url(/thumb-1920-1317036.png)` }}
      >
        <h1 className="text-4xl mb-16 text-center">
          <span className="text-orange-500">Technic</span> Notice
        </h1>
        <div className="flex flex-col lg:flex-row gap-x-8 text-start">
          <div className="flex-1">
            <h2 className="text-xl text-orange-500 mb-2">Comands</h2>
            <ul className="mb-6 list-disc list-inside">
              <li>/help</li>
              <li>/help</li>
              <li>/help</li>
              <li>/help</li>
              <li>/help</li>
            </ul>

            <h2 className="text-xl text-orange-500 mb-2">
              Supported Database Types
            </h2>
            <ul className="mb-6 list-disc list-inside">
              <li>MySQL</li>
              <li>MariaDB</li>
              <li>MongoDB</li>
            </ul>
          </div>
          <div className="flex-1">
            <h2 className="text-xl text-orange-500 mb-2">Supported Hooks</h2>
            <ul className="mb-6 list-disc list-inside">
              <li>lorem ipsum</li>
              <li>lorem ipsum</li>
              <li>lorem ipsum</li>
              <li>lorem ipsum</li>
              <li>lorem ipsum</li>
              <li>lorem ipsum</li>
              <li>lorem ipsum</li>
              <li>lorem ipsum</li>
              <li>lorem ipsum</li>
              <li>lorem ipsum</li>
            </ul>
          </div>
        </div>
      </div>
      {isPopupOpen && <NeedRegPopup onClose={closePopup} />}
    </div>
  );
};

export default Plugin;
