import { ArrowRightIcon, ChartBarIcon } from '@heroicons/react/24/solid';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Carousel from '../carousel/Carousel';
import News from '../news/News';
import ServerChart from '../serverChart/ServerChart';
import BotsStatistics from '../botsStatistics/BotsStatistics';
import Founders from '../founders/Founders';
import { Link } from 'react-router-dom';
import ContactUs from '../contactUs/ContactUs';

function Landing() {
  const { t } = useTranslation();

  return (
    <div className="w-full text-start">
      <Carousel />
      <News />

      {/* Заголовок з іконкою */}
      <div className="flex justify-between items-center mb-6">
        <p className="text-2xl text-white">
          <ChartBarIcon className="h-7 w-7 inline -mt-1" /> {t('server_statistics')}
        </p>
        <Link
          to="servers"
          className="self-end border-b border-transparent hover:border-white text-lg hidden sm:block"
        >
          {t('nav_servers')}
          <ArrowRightIcon className="ms-4 h-4 w-4 -mt-1 inline" />
        </Link>
      </div>

      {/* Контейнер для графіка */}
      <div className="flex -mx-4 sm:mx-0 lg:-mx-4 lg:drop-shadow-2xl">
      <div className="lg:flex-1 bg-gradient-to-l from-neutral-900 h-auto"></div>
      <div className="w-full lg:w-3/4 mx-auto sm:rounded-md lg:rounded-none bg-neutral-900 p-4 drop-shadow-md flex justify-center">
        <ServerChart />
      </div>
      <div className=" lg:flex-1 bg-gradient-to-r from-neutral-900 h-auto"></div>
      </div>

      <BotsStatistics />
      <Founders />
      <ContactUs/>
    </div>
  );
}

export default Landing;
