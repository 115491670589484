import {
  ChartBarIcon,
  BoltIcon,
  ArrowRightIcon,
} from "@heroicons/react/24/solid";
import React, { useEffect, useState } from "react";
import {
  FaTelegram,
  FaDiscord,
  FaInstagram,
  FaYoutube,
  FaTwitch,
  FaTiktok,
} from "react-icons/fa6";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getFounders } from "../../api/landingService";

function Founders() {
  const { t } = useTranslation();
  const lang = localStorage.getItem("i18nextLng") || "en";
  const [founders, setFounders] = useState([]);

  useEffect(() => {
    const fetchFounders = async () => {
      // setLoading(true);
      // setError(null);

      try {
        const result = await getFounders(); // Викликаємо функцію з передачею категорії

        setFounders(result); // Зберігаємо отримані продукти у стан
      } catch (err) {
        console.error(err.message || "Failed to fetch products");
      } finally {
        // setLoading(false);
      }
    };

    fetchFounders(); // Викликаємо функцію при завантаженні компонента
  }, []);

  function renderFounders() {
    return;
  }

  return (
    <div className="py-4">
      <div className="flex justify-between items-center mb-6">
        <p className="text-2xl text-white">
          <BoltIcon className="h-7 w-7 inline -mt-1" /> {t("founders")}
        </p>
        <Link
          to="about-us"
          className="self-end border-b border-transparent hover:border-white text-lg hidden sm:block"
        >
          {t("nav_about")}
          <ArrowRightIcon className="ms-4 h-4 w-4 -mt-1 inline" />
        </Link>
      </div>

      <div className="grid grid-cols-1 xl:grid-cols-2 gap-4">
        {founders.length > 0 ? (
          founders
            .sort((a, b) => a.priority - b.priority)
            .map((founder) => (
              <div
                className="p-3 bg-neutral-900 rounded-md drop-shadow-md gap-x-5 grid grid-cols-1 md:grid-cols-2"
                key={founder.nickname}
              >
                <div className="">
                  <img
                    src={founder.image_url}
                    className="w-2/3 xl:w-full  h-auto mx-auto"
                    alt=""
                  />
                </div>
                <div className="flex flex-col justify-between mt-3 md:mt-0">
                  <div className="flex flex-col justify-between">
                    <p className="text-lg font-bold flex-1">
                      {founder.nickname}
                    </p>
                    <p className="font-bold text-gray-300">
                      {founder.privilege[lang]}
                    </p>
                  </div>
                  <p className="text-sm my-5">{founder.description[lang]}</p>
                  <div className="self-end flex justify-self-end gap-x-4">
                    {founder.telegram && (
                      <Link target="_blank" to={founder.telegram}>
                        <FaTelegram className="h-7 w-7 transition-all duration-300 ease-in-out hover:text-orange-600" />
                      </Link>
                    )}
                    {founder.discord && (
                      <Link target="_blank" to={founder.discord}>
                        <FaDiscord className="h-7 w-7 transition-all duration-300 ease-in-out hover:text-orange-600" />
                      </Link>
                    )}
                    {founder.instagram && (
                      <Link target="_blank" to={founder.instagram}>
                        <FaInstagram className="h-7 w-7 transition-all duration-300 ease-in-out hover:text-orange-600" />
                      </Link>
                    )}
                    {founder.youtube && (
                      <Link target="_blank" to={founder.youtube}>
                        <FaYoutube className="h-7 w-7 transition-all duration-300 ease-in-out hover:text-orange-600" />
                      </Link>
                    )}
                    {founder.twitch && (
                      <Link target="_blank" to={founder.twitch}>
                        <FaTwitch className="h-7 w-7 transition-all duration-300 ease-in-out hover:text-orange-600" />
                      </Link>
                    )}
                    {founder.tiktok && (
                      <Link target="_blank" to={founder.tiktok}>
                        <FaTiktok className="h-7 w-7 transition-all duration-300 ease-in-out hover:text-orange-600" />
                      </Link>
                    )}
                  </div>
                </div>
              </div>
            ))
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}

export default Founders;
