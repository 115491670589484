import React, { createContext, useState, useContext, useEffect } from "react";
import { useSnackbar } from "./SnackbarContext";

const CartContext = createContext();

export const useCart = () => {
  return useContext(CartContext);
};

export const CartProvider = ({ children }) => {
  const { showSnackbar } = useSnackbar();
  const lang = localStorage.getItem("i18nextLng") || "en";

  const [cartItems, setCartItems] = useState(() => {
    const storedCart = localStorage.getItem("cart");
    return storedCart ? JSON.parse(storedCart) : {};
  });

  useEffect(() => {
    localStorage.setItem("cart", JSON.stringify(cartItems));
  }, [cartItems]);

  const addToCart = (product) => {
    const productId = product.id;
    setCartItems((prev) => {
      if (!prev[productId]) {
        showSnackbar("added_to_cart", "success", product.title[lang]);
      }
      return { ...prev, [productId]: product };
    });
  };

  const removeFromCart = (product) => {
    setCartItems((prev) => {
      const updatedCart = { ...prev };
      if (updatedCart[product.id]) {
        delete updatedCart[product.id];
        showSnackbar("removed_from_cart", "warning", product.title[lang]);
      }
      return updatedCart;
    });
  };

  const clearCart = () => {
    setCartItems({});
  };

  const isInCart = (productId) => !!cartItems[productId];

  const updateCartItem = (updatedItem) => {
    const productId = updatedItem.id;
    setCartItems((prev) => {
      if (prev[productId]) {
        return { ...prev, [productId]: updatedItem };
      }
      return prev;
    });
  };

  return (
    <CartContext.Provider
      value={{
        cartItems: Object.values(cartItems),
        addToCart,
        removeFromCart,
        isInCart,
        updateCartItem,
        clearCart,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};
