import { ChartBarIcon, UsersIcon } from "@heroicons/react/24/solid";
import { ServerIcon, UserIcon, HashtagIcon, ArrowRightIcon } from "@heroicons/react/24/outline";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import BotChart from "../botsChart/BotChart";
import { getBotData } from "../../api/landingService";

function BotsStatistics() {
  const { t } = useTranslation();

  const [statistics, setStatistics] = useState([]);
  
    useEffect(() => {
      const fetchStatistics = async () => {
        // setLoading(true);
        // setError(null);
  
        try {
          const result = await getBotData(); // Викликаємо функцію з передачею категорії
  
          setStatistics(result); // Зберігаємо отримані продукти у стан
        } catch (err) {
          console.error(err.message || "Failed to fetch products");
        } finally {
          // setLoading(false);
        }
      };
      fetchStatistics(); // Викликаємо функцію при завантаженні компонента
    }, []);

  function renderBots(bots) {
    return bots.map((botItem) => (
      <div
        className="p-2 md:p-3 bg-neutral-900 rounded-md drop-shadow-md flex gap-x-3"
        key={botItem.name}
      >
          <img className="w-14 h-full rounded-full bg-pink-300" src={botItem.icon_url} />
        <div className="text-start truncate flex flex-col gap-y-2">
          <p className="font-bold truncate">{botItem.name}</p>
          <p className="text-neutral-100 text-sm">
            <UsersIcon className="h-4 w-4 inline -mt-1 me-2" />
            {botItem.users}
          </p>
        </div>
      </div>
    ));
  }

  return (
    <div className="py-4">
      <div className="flex mb-6 justify-between">
      <p className="text-2xl text-white">
        <ChartBarIcon className="h-7 w-7 inline -mt-1" /> {t("bot_statistics")}
      </p>
      <Link to="bot" className="self-end border-b border-transparent hover:border-white text-lg hidden sm:block">{t("nav_bot")}<ArrowRightIcon className="ms-4 h-4 w-4 -mt-1 inline"/></Link>
      </div>
      <div className="-mx-4 flex justify-around md:justify-center sm:gap-x-12 md:gap-x-20 justify-center text-center md:text-start py-6 md:py-16 px-2 sm:px-4 bg-neutral-900">
        <div className="">
          <p className="text-2xl sm:text-3xl md:text-5xl">
            <ServerIcon className="h-7 w-7 md:h-12 md:w-12 inline -mt-2 md:-mt-3 me-2 md:me-4" />
            {statistics.servers}
          </p>
          <p className="text-sm sm:text-base md:text-xl">{t("nav_servers")}</p>
        </div>
        <div className="">
          <p className="text-2xl sm:text-3xl md:text-5xl">
            <UserIcon className="h-7 w-7 md:h-12 md:w-12 inline -mt-2 md:-mt-3 me-2 md:me-4" />
            {statistics.users}
          </p>
          <p className="text-sm sm:text-base md:text-xl">{t("users")}</p>
        </div>
        <div className="">
          <p className="text-2xl sm:text-3xl md:text-5xl">
            <HashtagIcon className="h-7 w-7 md:h-12 md:w-12 inline -mt-2 md:-mt-3 me-2 md:me-4" />
            {statistics.channels}
          </p>
          <p className="text-sm sm:text-base md:text-xl">{t("channels")}</p>
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 xl:grid-cols-2 gap-x-4 mt-6">
        <div className="w-full bg-neutral-900 rounded-md p-2 flex justify-center items-center drop-shadow-md">
          <BotChart servers={statistics.weekly_servers} users={statistics.weekly_users} />
        </div>
        <div className="mt-4 xl:mt-0">
          <p className="text-2xl font-bolder">{t("top_channelss")}</p>
          <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-2 gap-4 text-center mt-2 ">
            {statistics.top_servers ? renderBots(statistics.top_servers) : <>Боти відсутні</>}
          </div>
        </div>
      </div>
    </div>
  );
}

export default BotsStatistics;
