import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ProductCard from "../productCard/ProductCard";
import SkeletonLoader from "../productCard/SkeletonLoader"; // Імпортуємо SkeletonLoader
import { getProducts, getPurchasedProducts } from "../../api/shopsService";
import NeedRegPopup from "../needRegPopup/NeedRegPopup";
import { usePopup } from "../../context/PopupContext";
import { CheckIcon, ChevronDownIcon, FunnelIcon } from "@heroicons/react/24/solid";
import { motion, AnimatePresence } from "framer-motion";

const MinecraftShop = ({ userData }) => {
  const { t } = useTranslation();
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [purchasedProducts, setPurchasedProducts] = useState([]);
  const [purchasedLoading, setPurchasedLoading] = useState(false);
  const [error, setError] = useState(null);
  const [activeTypes, setActiveTypes] = useState([]); // Активні типи товарів
  const [sortOrder, setSortOrder] = useState("default"); // Сортування за ціною
  const [isSortMenuOpen, setIsSortMenuOpen] = useState(false); // Відкриття меню сортування
  const [showFilters, setShowFilters] = useState(false); // Відображення фільтрів на мобільних
  const { isPopupOpen, closePopup } = usePopup();

  useEffect(() => {
    const fetchProducts = async () => {
      setLoading(true);
      setError(null);

      try {
        const result = await getProducts("MINECRAFT"); 

        setProducts(result); // Зберігаємо отримані продукти у стан
        setActiveTypes([
          ...new Set(result.map((product) => product.product_type)),
        ]); // Встановлюємо всі типи товарів активними
      } catch (err) {
        setError(err.message || "Failed to fetch products");
      } finally {
        setLoading(false);
      }
    };

    fetchProducts();

    const fetchPurchasedProducts = async () => {
      setPurchasedLoading(true);
      setError(null);

      try {
        const result = await getPurchasedProducts(); // Видалено "MINECRAFT" із виклику функції

        setPurchasedProducts(result); // Зберігаємо отримані продукти у стан
      } catch (err) {
        setError(err.message || "Failed to fetch products");
      } finally {
        setPurchasedLoading(false);
      }
    };

    fetchPurchasedProducts(); // Викликаємо функцію при завантаженні компонента
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest(".sort-menu")) {
        setIsSortMenuOpen(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const toggleType = (type) => {
    if (activeTypes.includes(type)) {
      setActiveTypes(activeTypes.filter((t) => t !== type));
    } else {
      setActiveTypes([...activeTypes, type]);
    }
  };

  const handleSortChange = (value) => {
    setSortOrder(value);
    setIsSortMenuOpen(false);
  };

  const sortedAndFilteredProducts = products
    .filter((product) => activeTypes.includes(product.product_type))
    .sort((a, b) => {
      const priceA = a.price * (1 - a.discount);
      const priceB = b.price * (1 - b.discount);
      if (sortOrder === "price_asc") return priceA - priceB;
      if (sortOrder === "price_desc") return priceB - priceA;
      return 0; // Default order
    });

  return (
    <div className="mb-6">
      <div className="flex flex-col lg:flex-row md:items-center gap-4 justify-between mb-6">
        <p className="text-start text-2xl">{t("minecraft_shop_title")}</p>

        <div className="flex gap-4 justify-between">
        <button
          className="flex md:hidden items-center justify-center px-3 py-2 bg-neutral-700 text-white rounded-lg border border-neutral-600 hover:border-orange-600"
          onClick={() => setShowFilters(!showFilters)}
        >
          <FunnelIcon className="w-5 h-5" />
        </button>

        {/* Сортування */}
        <div className="flex-wrap gap-4 text-sm relative hidden md:flex">
          {/* Кнопки для типів товарів */}
          {Array.from(
            new Set(products.map((product) => product.product_type))
          ).map((type) => (
            <button
              key={type}
              className={`flex items-center gap-2 ${
                activeTypes.includes(type) ? "text-white" : "text-gray-300"
              } hover:text-orange-600`}
              onClick={() => toggleType(type)}
            >
              <span
                className={`w-5 h-5 flex items-center justify-center border ${
                  activeTypes.includes(type)
                    ? "border-white"
                    : "border-gray-300"
                }`}
              >
                {activeTypes.includes(type) && (
                  <CheckIcon className="w-4 h-4 text-white" />
                )}
              </span>
              {type.replace(/MINECRAFT/g, "").replace(/_/g, " ")}
            </button>
          ))}
        </div>

        <div className="relative">
          <button
            className="sort-menu flex items-center text-sm justify-between px-4 py-2 bg-neutral-700 text-white rounded-lg border border-neutral-600 hover:border-orange-600"
            onClick={() => setIsSortMenuOpen(!isSortMenuOpen)}
          >
            {sortOrder === "default"
              ? t("sort_default")
              : sortOrder === "price_asc"
              ? t("sort_price_ascending")
              : t("sort_price_descending")}
            <ChevronDownIcon className="w-5 h-5 ml-2" />
          </button>

          {isSortMenuOpen && (
            <div className="sort-menu absolute min-w-64 text-sm top-full right-0 mt-2 max-w-64 bg-neutral-700 border border-neutral-600 rounded-lg z-40">
              <button
                className="w-full text-left px-4 py-2 hover:bg-orange-600 rounded-lg"
                onClick={() => handleSortChange("default")}
              >
                {t("sort_default")}
              </button>
              <button
                className="w-full text-left px-4 py-2 hover:bg-orange-600 rounded-lg"
                onClick={() => handleSortChange("price_asc")}
              >
                {t("sort_price_ascending")}
              </button>
              <button
                className="w-full text-left px-4 py-2 hover:bg-orange-600 rounded-lg"
                onClick={() => handleSortChange("price_desc")}
              >
                {t("sort_price_descending")}
              </button>
            </div>
          )}
        </div>
      </div>

      <AnimatePresence>
        {showFilters && (
          <motion.div
            initial={{ height: 0, opacity: 0 }}
            animate={{ height: "auto", opacity: 1 }}
            exit={{ height: 0, opacity: 0 }}
            className="flex flex-wrap gap-4 text-sm"
          >
            {Array.from(
              new Set(products.map((product) => product.product_type))
            ).map((type) => (
              <button
                key={type}
                className={`flex items-center gap-2 ${
                  activeTypes.includes(type) ? "text-white" : "text-gray-300"
                } hover:text-orange-600`}
                onClick={() => toggleType(type)}
              >
                <span
                  className={`w-5 h-5 flex items-center justify-center border ${
                    activeTypes.includes(type)
                      ? "border-white"
                      : "border-gray-300"
                  }`}
                >
                  {activeTypes.includes(type) && (
                    <CheckIcon className="w-4 h-4 text-white" />
                  )}
                </span>
                {type.replace(/MINECRAFT/g, "").replace(/_/g, " ")}
              </button>
            ))}
          </motion.div>
        )}
      </AnimatePresence>
      </div>
      {/* Продукти або SkeletonLoader */}
      <div className="grid sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-5 gap-2 md:gap-4">
        {loading ? (
          // Виводимо 5 SkeletonLoader
          Array.from({ length: 5 }, (_, index) => (
            <SkeletonLoader key={"item_" + index} />
          ))
        ) : sortedAndFilteredProducts.length > 0 ? (
          sortedAndFilteredProducts.map((product) => (
            <ProductCard
              key={product.id}
              product={product}
              userData={userData}
            />
          ))
        ) : (
          <p className="col-span-full text-center text-gray-400">
            {t("no_products_available")}
          </p>
        )}
      </div>

      <p className="text-start text-2xl my-6">{t("recent_purchases_title")}</p>

      {/* Продукти або SkeletonLoader */}
      <div className="grid sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-5 gap-2 md:gap-4">
        {purchasedLoading ? (
          // Виводимо 5 SkeletonLoader
          Array.from({ length: 5 }, (_, index) => (
            <SkeletonLoader key={index} />
          ))
        ) : purchasedProducts.length > 0 ? (
          purchasedProducts.map((product) => (
            <ProductCard
              key={"pur_" + product.id}
              product={product}
              userData={userData}
            />
          ))
        ) : (
          <p className="col-span-full text-center text-gray-400">
            {t("no_products_available")}
          </p>
        )}
      </div>
      {isPopupOpen && <NeedRegPopup onClose={closePopup} />}
    </div>
  );
};

export default MinecraftShop;
