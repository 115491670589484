import { useTranslation } from "react-i18next";
import { AiOutlineWarning } from "react-icons/ai";
import { FaArrowRightToBracket, FaUserPlus, FaXmark  } from "react-icons/fa6";
import { Link } from "react-router-dom";

const NeedRegPopup = ({ children, onClose }) => {
  const { t } = useTranslation();
  return (
    <div
      className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50"
      onClick={onClose}
    >
      <div className="bg-neutral-700 p-4 pt-10 rounded-lg shadow-lg max-w-md w-full mx-2 relative">
        
      <FaXmark className="absolute top-3 right-3 w-6 h-6 cursor-pointer hover:text-orange-500" onClick={onClose}/>
        <div className="flex items-center mb-4">
          <AiOutlineWarning className="text-orange-500 w-6 h-6 sm:w-8 sm:h-8 mr-2" />
          <h2 className="sm:text-lg font-bold">{t("need_registration")}</h2>
        </div>
        <p className="text-gray-200 mb-6">
        {t("need_registration_desc")}
        </p>
        <div className="flex flex-col sm:flex-row justify-end gap-4">
          <Link
            to={"/registration"}
            className="bg-orange-600 hover:bg-orange-700 text-white font-semibold py-2 px-4 rounded-lg"
          >
             <FaUserPlus className="inline me-3 -mt-1" />
            {t("register")}
          </Link>
          <Link
            to={"/auth"}
            className="bg-orange-600 hover:bg-orange-700 text-white font-semibold py-2 px-4 rounded-lg"
          >
             <FaArrowRightToBracket className="inline me-3 -mt-1" />
            {t("auth_login")}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default NeedRegPopup;
