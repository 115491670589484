import axios from "axios";

const API = "https://api.magatamy.com";

export const getFounders = async () => {
  try {
    const response = await axios.get(`${API}/staff`);
    
    return response.data;
  } catch (error) {
    console.error("getFounders error:", error);
    throw new Error(error.response?.data?.message || "getFounders failed");
  }
};

export const getBotData = async () => {
    try {
      const response = await axios.get(`${API}/discord/botData?bot_id=1294659901120970873`);
      
      return response.data;
    } catch (error) {
      console.error("getBotData error:", error);
      throw new Error(error.response?.data?.message || "getBotData failed");
    }
  };

  export const getServersOnline = async () => {
    try {
      const response = await axios.get(`${API}/minecraft/serverOnline`);
      
      return response.data;
    } catch (error) {
      console.error("getServersOnline error:", error);
      throw new Error(error.response?.data?.message || "getServersOnline failed");
    }
  };

  export const getNews = async () => {
    try {
      const response = await axios.get(`${API}/news`);
      
      return response.data;
    } catch (error) {
      console.error("getNews error:", error);
      throw new Error(error.response?.data?.message || "getNews failed");
    }
  };