import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { emailCode, registration } from "../../api/authService";

function Registration() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [displayName, setDisplayName] = useState("");
  const [nickname, setNickname] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [code, setCode] = useState("");
  const [isCodeSent, setIsCodeSent] = useState(false);
  const [timer, setTimer] = useState(0);
  const [step, setStep] = useState(1);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const isDisplayName = displayName.length >= 6;
  const isNicknameValid = nickname.length >= 6;
  const isEmailValid = emailRegex.test(email);
  const isPasswordValid = password.length >= 6;
  const isPasswordMatch = password === confirmPassword;
  const isFormValid = isEmailValid && isPasswordValid && isPasswordMatch;

  const handleSendCode = async () => {
    setLoading(true);
    try {
      await emailCode(email);
      setIsCodeSent(true);
      setTimer(60);

      const countdown = setInterval(() => {
        setTimer((prev) => {
          if (prev <= 1) {
            clearInterval(countdown);
            setIsCodeSent(false);
            return 0;
          }
          return prev - 1;
        });
      }, 1000);
    } catch (error) {
      alert(t("code_send_error"));
    } finally {
      setLoading(false);
    }
  };

  const handleNextStep = async () => {
    if (isFormValid) {
      try {
        await handleSendCode();
        setStep(2);
      } catch (error) {
        alert(t("code_send_error"));
      }
    }
  };

  const handleConfirmCode = async () => {
    setLoading(true);
    setError(null);
    try {
      await registration(displayName, nickname, email, code, password, confirmPassword);
      navigate("/auth");
    } catch (error) {
      console.log(error);
      
      setError(error.message || t("registration_failed"));
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="h-full flex items-center justify-center text-white mb-10">
      <div className="w-full max-w-md">
        <h2 className="text-2xl font-bold text-center mb-4">{t("register")}</h2>

        {step === 1 && (
          <div className="space-y-4">
            <form className="space-y-4 text-start">
              <div>
                <label className="block text-neutral-400 mb-1">
                  {t("nickname")}
                </label>
                <input
                  type="text"
                  value={displayName}
                  onChange={(e) => setDisplayName(e.target.value)}
                  placeholder={t("nickname_placeholder")}
                  className={`w-full p-2 rounded bg-neutral-700 border ${
                    isDisplayName || !displayName
                      ? "border-neutral-600"
                      : "border-red-500"
                  } focus:outline-none focus:border-orange-500`}
                />
              </div>
              <div>
                <label className="block text-neutral-400 mb-1">
                  {t("login")}
                </label>
                <input
                  type="text"
                  value={nickname}
                  onChange={(e) => setNickname(e.target.value)}
                  placeholder={t("login_placeholder")}
                  className={`w-full p-2 rounded bg-neutral-700 border ${
                    isNicknameValid || !nickname
                      ? "border-neutral-600"
                      : "border-red-500"
                  } focus:outline-none focus:border-orange-500`}
                />
              </div>
              <div>
                <label className="block text-neutral-400 mb-1">
                  {t("email")}
                </label>
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder={t("email_placeholder")}
                  className={`w-full p-2 rounded bg-neutral-700 border ${
                    isEmailValid || !email
                      ? "border-neutral-600"
                      : "border-red-500"
                  } focus:outline-none focus:border-orange-500`}
                />
              </div>
              <div>
                <label className="block text-neutral-400 mb-1">
                  {t("password")}
                </label>
                <input
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder={t("password_placeholder")}
                  className={`w-full p-2 rounded bg-neutral-700 border ${
                    isPasswordValid || !password
                      ? "border-neutral-600"
                      : "border-red-500"
                  } focus:outline-none focus:border-orange-500`}
                />
              </div>
              <div>
                <label className="block text-neutral-400 mb-1">
                  {t("confirm_password")}
                </label>
                <input
                  type="password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  placeholder={t("confirm_password_placeholder")}
                  className={`w-full p-2 rounded bg-neutral-700 border ${
                    isPasswordMatch || !confirmPassword
                      ? "border-neutral-600"
                      : "border-red-500"
                  } focus:outline-none focus:border-orange-500`}
                />
              </div>
              <button
                type="button"
                onClick={handleNextStep}
                disabled={!isFormValid || loading}
                className={`w-full py-2 rounded-md ${
                  isFormValid && !loading
                    ? "bg-orange-500 hover:bg-orange-600 text-white"
                    : "bg-neutral-500 text-neutral-300 cursor-not-allowed"
                }`}
              >
                {loading ? t("loading") : t("next_step")}
              </button>
            </form>
          </div>
        )}

        {step === 2 && (
          <div className="space-y-4 text-start">
            <p className="text-neutral-400">{t("code_sent_to", { email })}</p>
            <div>
              <label className="block text-neutral-400 mb-1">
                {t("confirmation_code")}
              </label>
              <input
                type="text"
                value={code}
                onChange={(e) => setCode(e.target.value)}
                placeholder={t("confirmation_code_placeholder")}
                className="w-full p-2 rounded bg-neutral-700 border border-neutral-600 focus:outline-none focus:border-orange-500"
              />
              <button
                type="button"
                onClick={handleSendCode}
                disabled={isCodeSent || loading}
                className={`mt-2 px-4 py-2 text-sm rounded ${
                  isCodeSent
                    ? "bg-neutral-500 text-neutral-300 cursor-not-allowed"
                    : "bg-orange-500 text-white hover:bg-orange-600"
                }`}
              >
                {isCodeSent
                  ? t("resend_code_in", { seconds: timer })
                  : t("resend_code")}
              </button>
            </div>
            <button
              type="button"
              onClick={handleConfirmCode}
              disabled={loading}
              className="w-full py-2 rounded-md bg-orange-500 hover:bg-orange-600 text-white"
            >
              {loading ? t("loading") : t("confirm")}
            </button>

            {error && <p className="text-red-500 text-center mt-2">{error}</p>}

            <button
              type="button"
              onClick={() => setStep(1)}
              className="w-full py-2 rounded-md bg-neutral-700 hover:bg-neutral-600 text-white mt-4"
            >
              {t("back")}
            </button>
          </div>
        )}

        <p className="text-neutral-500 mt-4 text-center">
          {t("already_have_account")}{" "}
          <Link to="/auth" className="text-orange-500 hover:underline">
            {t("login_here")}
          </Link>
        </p>
      </div>
    </div>
  );
}

export default Registration;
