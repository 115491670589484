import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  XCircleIcon,
  PlusIcon,
  MinusIcon,
  ArrowPathIcon,
} from "@heroicons/react/24/solid";
import { MdShoppingCart } from "react-icons/md";
import { useCart } from "../../context/CartContext"; // Використовуємо оновлений CartContext
import { getAdminGuilds, checkPromoCode } from "../../api/shopsService"; // Додано імпорт checkPromoCode
import { Link } from "react-router-dom";
import { FaDiscord } from "react-icons/fa6";

function CheckoutForm({
  cartItems,
  setDiscount,
  setMinecraftNickname,
  discordServerSelected,
  setDiscordServerSelected,
  email,
  setEmail,
}) {
  const { t } = useTranslation();
  const [isServersVisible, setIsServersVisible] = useState(false);
  const [discordServers, setDiscordServers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [promoCode, setPromoCode] = useState(""); // Стан для промокоду
  const [promoCodeValid, setPromoCodeValid] = useState(null); // Стан для перевірки дійсності промокоду
  const [promoDiscount, setPromoDiscount] = useState(null);
  const [isForbidden, setIsForbidden] = useState();

  const link = (service) => {
    return `https://api.magatamy.com/authorize/login${service}?jwt=${localStorage.getItem(
      "jwtToken"
    )}`;
  };

  const toggleServersVisibility = async () => {
    if (isServersVisible) {
      setIsServersVisible(false); // Згортаємо список
    } else {
      setIsServersVisible(true); // Розгортаємо список

      if (discordServers.length === 0) {
        setLoading(true);
        try {
          const servers = await getAdminGuilds();
          setDiscordServers(servers); // Оновлюємо список серверів
          setIsForbidden(false); // Скидаємо статус помилки
        } catch (error) {
          if (error.message === "FORBIDDEN") {
            setIsForbidden(true); // Встановлюємо статус 403
          } else {
            console.error("Failed to fetch Discord servers:", error);
            setDiscordServers([]); // У разі інших помилок
          }
        }
        setLoading(false);
      }
    }
  };

  const handlePromoCodeChange = (e) => {
    setPromoCode(e.target.value);
    setPromoCodeValid(null); // Скидаємо стан перевірки промокоду, поки не натиснули кнопку
  };

  const handleCheckPromoCode = async () => {
    if (!promoCode) return;
    try {
      const response = await checkPromoCode(promoCode.toUpperCase()); // Перевірка промокоду
      if (response && response.discount) {
        setPromoCodeValid(true); // Промокод дійсний
        setDiscount(response.discount); // Встановлюємо знижку
        setPromoDiscount(response.discount);
      } else {
        setPromoCodeValid(false); // Промокод недійсний
        setDiscount(0); // Скидаємо знижку
        setPromoDiscount(0);
      }
    } catch (error) {
      console.error("Error checking promo code:", error);
      setPromoCodeValid(false); // Промокод недійсний
      setDiscount(0); // Скидаємо знижку
      setPromoDiscount(0);
    }
  };

  return (
    <div className="mt-4">
      {cartItems.length ? (
        <>
          <input
            type="text"
            value={promoCode.toUpperCase()}
            onChange={handlePromoCodeChange}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleCheckPromoCode(); // Викликаємо функцію при натисканні Enter
              }
            }}
            placeholder={t("promo_placeholder")}
            className="w-full p-2 mt-2 rounded bg-neutral-700 border border-neutral-600 focus:outline-none focus:border-orange-500 text-center"
            disabled={promoCodeValid === true} // Якщо промокод дійсний, поле буде неактивним
          />

          {/* Кнопка перевірки промокоду */}
          {promoCode && promoCodeValid === null && (
            <button
              onClick={handleCheckPromoCode}
              className="mt-2 w-full py-2 rounded-md bg-green-600 hover:bg-green-700 text-white"
            >
              {t("check_promo_code")}
            </button>
          )}

          {promoCodeValid === true && (
            <p className="text-green-500 mt-2 text-center text-sm">
              {t("promo_code_valid", { promocode: promoDiscount * 100 })}
            </p>
          )}
          {promoCodeValid === false && (
            <p className="text-red-500 mt-2 text-center text-sm">
              {t("promo_code_invalid")}
            </p>
          )}
        </>
      ) : null}

      {/* Відображення поля вводу для email */}
      {cartItems.length > 0 && (
        <div className="mt-4">
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)} // Зміна стану при введенні email
            placeholder={t("email_placeholder")}
            className="w-full p-2 mt-2 rounded bg-neutral-700 border border-neutral-600 focus:outline-none focus:border-orange-500"
            required
          />
        </div>
      )}

      {/* Відображення поля вводу для нікнейму Minecraft */}
      {cartItems.some((item) => item.category === "MINECRAFT") && (
        <div className="mt-2">
          <input
            type="text"
            placeholder={t("minecraft_nickname_placeholder")}
            onChange={(e) => setMinecraftNickname(e.target.value)}
            className="w-full p-2 mt-2 rounded bg-neutral-700 border border-neutral-600 focus:outline-none focus:border-orange-500"
          />
        </div>
      )}

      {cartItems.some((item) => item.category === "DISCORD") && (
        <div className="mt-4">
          {isForbidden ? (
            // Відображення посилання для підключення Discord
            <Link
              to={link("Discord")}
              className="w-full flex items-center justify-center bg-blue-600 text-white py-2 rounded-md hover:bg-blue-700"
            >
              <FaDiscord alt="Discord" className="h-5 w-5 mr-2" />
              {t("auth_login_discord")}
            </Link>
          ) : (
            <>
              <button
                onClick={toggleServersVisibility}
                className={`w-full py-2 rounded-md ${
                  isServersVisible
                    ? "bg-red-600 hover:bg-red-700"
                    : "bg-blue-600 hover:bg-blue-700"
                } text-white`}
              >
                {isServersVisible
                  ? t("hide_discord_servers")
                  : t("show_discord_servers")}
              </button>
              {isServersVisible && (
                <div className="mt-2 space-y-2">
                  {loading ? (
                    <p className="text-center text-lg">
                      <ArrowPathIcon className="animate-spin h-6 w-6 -mt-1 mr-3 inline" />{" "}
                      {t("loading")}
                    </p>
                  ) : (
                    discordServers?.map((server) => (
                      <div
                        key={server.id}
                        className={`flex items-center gap-2 p-2 rounded-md cursor-pointer ${
                          discordServerSelected === server.id
                            ? "bg-orange-500 text-white"
                            : "bg-neutral-700 text-gray-300"
                        } hover:bg-orange-700`}
                        onClick={() => setDiscordServerSelected(server.id)}
                      >
                        <img
                          src={
                            server.icon_url &&
                            !server.icon_url.endsWith("None.png")
                              ? server.icon_url
                              : "/discord-logo.jpeg"
                          }
                          alt={`${server.name} avatar`}
                          className="w-8 h-8 rounded-full"
                        />
                        <span>{server.name}</span>
                      </div>
                    ))
                  )}
                </div>
              )}
            </>
          )}
          {/* Якщо вибраний сервер, відображаємо його навіть коли список згорнуто */}
          {discordServerSelected && !isServersVisible && (
            <div className="flex items-center gap-2 p-2 rounded-md bg-neutral-700 mt-2">
              <img
                src={
                  discordServers
                    ?.find((server) => server.id === discordServerSelected)
                    ?.icon_url?.endsWith("None.png")
                    ? "/discord-logo.jpeg"
                    : discordServers?.find(
                        (server) => server.id === discordServerSelected
                      )?.icon_url || "/discord-logo.jpeg"
                }
                alt="Selected Discord server"
                className="w-8 h-8 rounded-full"
              />
              <span className="text-white">
                {discordServers?.find(
                  (server) => server.id === discordServerSelected
                )?.name || t("select_discord_server")}
              </span>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

function renderGoods(
  cartItems,
  increaseQuantity,
  decreaseQuantity,
  removeItem,
  t,
  discount
) {
  const lang = localStorage.getItem("i18nextLng") || "en";
  return cartItems.map((item) => {
    const finalPrice = item.price * (1 - (item.discount || 0)) * (1 - discount); // Знижка по промокоду
    const quantity = item.quantity || 1;

    return (
      <div
        key={item.id}
        className="bg-neutral-900 rounded-md bg-cover drop-shadow-md p-2 flex gap-x-2 md:gap-x-4 items-center"
      >
        <img
          src={item.image_url}
          className="h-16 w-16 sm:h-20 sm:w-20 md:h-24 md:w-24 rounded-md"
          alt={t("goods_item_alt", { title: item.title[lang] })}
        />
        <div className="flex-1 text-start flex flex-col gap-y-2">
          <p className="mb-1 text-sm sm:text-base font-semibold text-white">
            {t(item.title[lang])}
          </p>
          <div className="text-sm">
            {item.discount > 0 && (
              <span className="line-through mr-2 text-gray-400">
                ${(item.price * quantity).toFixed(2)}
              </span>
            )}
            {!item.discount && finalPrice !== item.price && (
              <span className="line-through mr-2 text-gray-400">
                ${(item.price * quantity).toFixed(2)}
              </span>
            )}
            <span>${(finalPrice * quantity).toFixed(2)}</span>
          </div>
        </div>
        {item.numeric_product === true ? (
          <div className="flex items-center gap-x-0 md:gap-x-2">
            <button
              onClick={() => decreaseQuantity(item.id)}
              className="text-gray-300 hover:text-white"
            >
              <MinusIcon className="h-5 w-5" />
            </button>
            <span className="px-1 sm:px-2 text-white">{quantity}</span>
            <button
              onClick={() => increaseQuantity(item.id)}
              className="text-gray-300 hover:text-white"
            >
              <PlusIcon className="h-5 w-5" />
            </button>
          </div>
        ) : (
          <></>
        )}
        <button
          onClick={() => removeItem(item)}
          className="self-start text-pink-600 hover:text-pink-700 text-white"
        >
          <XCircleIcon className="h-5 w-5 md:h-7 md:w-7" />
        </button>
      </div>
    );
  });
}

function Cart() {
  const { t } = useTranslation();
  const { cartItems, updateCartItem, removeFromCart } = useCart();
  const [discount, setDiscount] = useState(0);
  const [minecraftNickname, setMinecraftNickname] = useState(""); // Стан для нікнейму Minecraft
  const [discordServerSelected, setDiscordServerSelected] = useState(false); // Стан для вибору сервера Discord
  const [email, setEmail] = useState(""); // Додаємо стан для email

  const increaseQuantity = (id) => {
    const product = cartItems.find((item) => item.id === id);
    if (product) {
      updateCartItem({ ...product, quantity: (product.quantity || 1) + 1 });
    }
  };

  const decreaseQuantity = (id) => {
    const product = cartItems.find((item) => item.id === id);
    if (product && (product.quantity || 1) > 1) {
      updateCartItem({ ...product, quantity: product.quantity - 1 });
    }
  };

  const removeItem = (id) => {
    removeFromCart(id);
  };

  const totalOriginalAmount = cartItems.reduce(
    (sum, item) => sum + item.price * (item.quantity || 1),
    0
  );

  const totalDiscountedAmount = cartItems.reduce(
    (sum, item) =>
      sum + item.price * (1 - (item.discount || 0)) * (item.quantity || 1),
    0
  );

  const totalItems = cartItems.reduce(
    (sum, item) => sum + (item.quantity || 1),
    0
  );

  // Логіка для перевірки, чи можна активувати кнопку
  const isCheckoutEnabled = () => {
    // Перевірка на наявність товарів в кошику
    if (cartItems.length === 0) return false;

    const isMinecraftNicknameValid = !cartItems.some(
      (item) => item.category === "MINECRAFT" && !minecraftNickname
    );
    const isDiscordServerSelectedValid = !cartItems.some(
      (item) => item.category === "DISCORD" && !discordServerSelected
    );

    return (
      email &&
      email.includes("@") &&
      isMinecraftNicknameValid &&
      isDiscordServerSelectedValid
    );
  };

  return (
    <div className="md:px-6 min-h-screen">
      <p className="text-2xl text-white">
        <MdShoppingCart className="h-6 w-6 inline -mt-1" /> {t("cart")}
      </p>
      <div className="grid md:grid-cols-6 gap-4 mt-4">
        <div className="md:col-span-4 flex flex-col gap-4">
          {cartItems.length > 0 ? (
            renderGoods(
              cartItems,
              increaseQuantity,
              decreaseQuantity,
              removeItem,
              t,
              discount
            )
          ) : (
            <p className="text-gray-500 text-center">{t("cart_empty")}</p>
          )}
        </div>
        <div className="md:col-span-2 bg-neutral-800 p-4 pt-0 rounded-md">
          <p className="text-xl font-semibold text-white mb-4">
            {t("summary")}
          </p>
          <div className="flex justify-between">
            <p className="text-gray-400">{t("items")}:</p>
            <p className="text-white">{totalItems}</p>
          </div>
          {totalOriginalAmount.toFixed(2) !==
          totalDiscountedAmount.toFixed(2) ? (
            <div className="flex justify-between mt-2">
              <p className="text-gray-400">{t("total_without_discount")}: </p>
              <p className="text-gray-400">${totalOriginalAmount.toFixed(2)}</p>
            </div>
          ) : (
            <></>
          )}
          <div className="flex justify-between mt-2">
            <p className="text-gray-400">{t("total")}: </p>
            <p className="text-white">
              ${(totalDiscountedAmount * (1 - discount)).toFixed(2)}
            </p>{" "}
            {/* Знижка на фінальну суму */}
          </div>
          <CheckoutForm
            cartItems={cartItems}
            setDiscount={setDiscount}
            setMinecraftNickname={setMinecraftNickname} // Передаємо функцію для збереження нікнейму
            discordServerSelected={discordServerSelected}
            setDiscordServerSelected={setDiscordServerSelected} // Передаємо функцію для збереження вибору сервера
            email={email} // Передаємо email
            setEmail={setEmail} // Передаємо функцію для зміни email
          />
          <button
            onClick={() => {
              /* Логіка для оформлення замовлення */
            }}
            className={`mt-4 w-full py-2 rounded-md text-white ${
              isCheckoutEnabled()
                ? "bg-orange-500 hover:bg-orange-600"
                : "bg-gray-400 cursor-not-allowed"
            }`}
            disabled={!isCheckoutEnabled()} // Вимикаємо кнопку, якщо умови не виконано
          >
            {t("checkout")}
          </button>
        </div>
      </div>
    </div>
  );
}

export default Cart;
