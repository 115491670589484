import { Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import LanguageSwitcher from "../languageSwitcher/LanguageSwitcher";
import { UserCircleIcon, Bars3Icon, UserIcon } from "@heroicons/react/24/solid";
import { MdShoppingCart } from "react-icons/md";
import { useTranslation } from "react-i18next";
import { useCart } from "../../context/CartContext";
import { useSnackbar } from "../../context/SnackbarContext";
import { FaArrowRightToBracket } from "react-icons/fa6";
import { getMain } from "../../api/minecraftService";

function Header({ toggleMobileMenu, userData }) {
  const [isOnline, setIsOnline] = useState(null);
  const [usersOnline, setUsersOnline] = useState(0);
  const { cartItems } = useCart();
  const cartCount = cartItems.length;
  const [isHovered, setIsHovered] = useState(false);
  const { t } = useTranslation();
  const { showSnackbar } = useSnackbar();
  const serverIP = "mc.magatamy.com";
  const copyToClipboard = (text) => {
    if (
      navigator.clipboard &&
      typeof navigator.clipboard.writeText === "function"
    ) {
      // Використовуємо Clipboard API, якщо він доступний
      navigator.clipboard
        .writeText(text)
        .then(() => showSnackbar("text_copied", "success"))
        .catch(() => showSnackbar("failed_to_copy", "error"));
    } else {
      // Альтернативний метод для старих браузерів або мобільних пристроїв
      try {
        const textarea = document.createElement("textarea");
        textarea.value = text;
        textarea.style.position = "fixed"; // Щоб не викликати скролінг
        textarea.style.opacity = "0"; // Робимо невидимим
        document.body.appendChild(textarea);
        textarea.focus();
        textarea.select();
        const successful = document.execCommand("copy");
        document.body.removeChild(textarea);

        if (successful) {
          showSnackbar("text_copied", "success");
        } else {
          throw new Error("Copy failed");
        }
      } catch (error) {
        showSnackbar("failed_to_copy", "error");
      }
    }
  };

  useEffect(() => {
    const fetchMain = async () => {
      try {
        const data = await getMain();
        await setIsOnline(data.is_online);
        await setUsersOnline(data.online);
      } catch (error) {
        console.error("Error fetching minecraft server data:", error);
      }
    };

    fetchMain();
  }, []);

  return (
    <div className="w-auto justify-between px-4 h-16 text-start flex items-center gap-x-8 bg-gradient-to-b from-neutral-900/90 backdrop-blur-sm">
      <div
        className="hidden lg:block relative"
        onClick={() => copyToClipboard(serverIP)}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        {isOnline == null ? (
          <div className="bg-neutral-400 h-7 w-64 rounded-full animate-pulse "></div>
        ) : isOnline ? (
          <div className="border border-2 flex border-neutral-400 text-neutral-300 pe-5 rounded-full items-center cursor-pointer text-sm">
            <div className="ps-5 pe-3 py-1 border-e-2 border-neutral-400 me-3 space-x-1">
            <span>{usersOnline}</span>
            <UserIcon className="inline h-4 w-4 -mt-1"/>
            </div>
            <span className="-mt-px">{serverIP}</span>
            <span className="relative h-3 w-3 ms-3 -mt-2">
              <span className="animate-ping absolute inline-flex h-3 w-3 top-1 rounded-full bg-green-500 opacity-75"></span>
              <span className="relative inline-flex rounded-full h-3 w-3 bg-green-500"></span>
            </span>
          </div>
        ) : (
          <p className="border border-2 py-px flex border-neutral-400 text-neutral-300 px-5 rounded-full items-center cursor-pointer text-sm">
            <span className="py-1 -mt-px">{serverIP}</span>
            <span className="relative inline-flex rounded-full h-3 w-3 bg-red-500 ms-2"></span>
          </p>
        )}
        {isHovered && (
          <div
            className="absolute bottom-0 left-1/2 transform -translate-x-1/2 translate-y-8 px-3 py-1 bg-neutral-700 text-white text-sm rounded shadow-lg flex items-center justify-center
          before:content-[''] before:absolute before:-top-3 before:left-1/2 before:-translate-x-1/2 before:border-[8px] before:border-transparent before:border-b-neutral-700"
          >
            {t("copy")}
          </div>
        )}
      </div>
      <div className="flex-1 lg:flex-initial flex justify-between items-center">
        <Link to={"/"} className="lg:hidden">
          <img src="/logo.png" className="h-11 w-11" alt="" />
        </Link>
        <div className="flex items-center gap-x-8">
          <LanguageSwitcher />
          <Link className="relative" to="/cart">
            {cartCount > 0 && (
              <div className="p-1 bg-pink-600 rounded-full border border-1 border-pink-600 absolute -top-1 -right-1"></div>
            )}
            <MdShoppingCart className="h-8 w-8 inline -mt-1" />
          </Link>

          {userData ? (
            <Link to="profile" className="">
              {/* <UserCircleIcon className="w-9 h-9 -mt-1" /> */}
              <img
                src={userData.avatar_url}
                className="w-9 h-9 rounded-full"
                alt=""
              />
            </Link>
          ) : (
            <div className="">
              <Link
                to={"auth"}
                className="-mt-1 flex items-center justify-center min-w-9 h-9 rounded-full md:px-5 bg-white/10 lg:hover:bg-white/20"
              >
                <FaArrowRightToBracket className="inline" />
                <p className="hidden md:block ms-3">{t("auth_login")}</p>
              </Link>
              {/* <div className="hidden lg:flex gap-x-4">
              <Link to={"auth"} className="px-4 py-1 bg-black/30 hover:bg-orange-700 text-sm rounded-full ">{t("auth_login")}</Link>
              <Link to={"registration"} className="px-4 py-1 bg-black/30 hover:bg-orange-700 text-sm rounded-full ">{t("register")}</Link>
            </div> */}
            </div>
          )}
          <button className="lg:hidden text-white" onClick={toggleMobileMenu}>
            <Bars3Icon className="h-9 w-9" />
          </button>
        </div>
      </div>
    </div>
  );
}

export default Header;
