import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function AuthorizeCallback() {
  const navigate = useNavigate();

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const accessToken = params.get('access_token');

    if (accessToken) {
      // Зберігаємо токен у localStorage
      localStorage.setItem('jwtToken', accessToken);

      // Перенаправляємо на /profile
      navigate('/profile');
    } else {
      // Якщо токена немає, перенаправляємо на сторінку помилки
      navigate('/');
    }
  }, [navigate]);

  return (
    <div className="text-center text-white">
      <p>Ending authorization...</p>
    </div>
  );
}

export default AuthorizeCallback;
