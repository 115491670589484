import { FireIcon, NewspaperIcon, ClockIcon } from "@heroicons/react/24/solid";
import React, { Suspense, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import NewsPreload from "./NewsPreload";
import { getNews } from "../../api/landingService";

function formatTimestamp(timestamp) {
  const date = new Date(timestamp);
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Місяці починаються з 0
  const year = date.getFullYear();

  return `${hours}:${minutes} ${day}.${month}.${year}`;
}

// Функція для рендерингу новин
function renderNews(newsArray) {
  const lang = localStorage.getItem("i18nextLng") || "en";
  return newsArray.map((newsItem, index) => (
    <div
      key={index}
      className="bg-orange-950 rounded-md min-h-64 bg-cover drop-shadow-lg"
      style={{ backgroundImage: `url('${newsItem.image_url}')` }}
    >
      <div className="h-full bg-gradient-to-b from-transparent to-pink-950 flex flex-col justify-between items-end p-2 rounded-md">
        <div className="p-3 w-full flex-1 flex flex-col justify-between">
          <div className="mb-5">
            <p className="text-xs mb-2 tracking-wider">
              <ClockIcon className="w-4 h-4 inline me-1 -mt-px" />
              {formatTimestamp(newsItem.create_ts)}
            </p>
            <div className="flex items-center gap-x-2">
            {!index ? (
                  <div className="">
                  <FireIcon className="w-8 h-8 md:w-6 md:h-6 p-1 bg-pink-600 rounded-full inline" />
                  </div>
                ) : (
                  <></>
                )}
              <p className="text-lg font-medium tracking-wider">
                {newsItem.title[lang]}
              </p>
            </div>
          </div>
          <p className="text-sm justify-self-end tracking-wider">
            {newsItem.description[lang]}
          </p>
        </div>
      </div>
    </div>
  ));
}

function News() {
  const { t } = useTranslation();
  const [newsData, setNewsData] = useState();
  const [error, setError] = useState();
  useEffect(() => {
    const fetchNews = async () => {
      try {
        const result = await getNews();

        setNewsData(result);
      } catch (err) {
        setError(err.message || "Failed to fetch products");
      }
    };

    fetchNews();
  }, []);

  return (
    <div className="">
      <p className="text-2xl text-white">
        <NewspaperIcon className="h-7 w-7 inline -mt-1" /> {t("news")}
      </p>

      <Suspense fallback={<NewsPreload />}>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 py-4">
          {newsData?.length > 0 ? renderNews(newsData) : <NewsPreload />}
        </div>
      </Suspense>
    </div>
  );
}

export default News;
