const ServersPreload = () => (
  <div className="h-full bg-neutral-900 rounded-lg animate-pulse flex flex-col justify-between gap-y-20">
    <div className="bg-neutral-700 h-10 rounded-t-lg"></div>
    <div className="h-14 bg-neutral-500 rounded-lg mx-12"></div>
    <div className="">
      <div className="p-4">
        <div className="h-6 bg-neutral-600 mb-2 rounded"></div>
        <div className="h-4 bg-neutral-600 mb-2 rounded"></div>
        <div className="h-4 bg-neutral-600 mb-2 rounded"></div>
      </div>
      <div className="h-10 bg-neutral-700 rounded-b-lg"></div>
    </div>
  </div>
);

export default ServersPreload;
