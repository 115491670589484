import React, { useState } from "react";
import { FaEnvelope, FaPaperclip, FaXmark, FaFile } from "react-icons/fa6";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "../../context/SnackbarContext";
function ContactUs() {
  const { showSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const [files, setFiles] = useState([]);

  // Функція для обробки завантаження файлів
  const handleFileUpload = (e) => {
    const uploadedFiles = Array.from(e.target.files);

    // Перевірка типу файлу та максимального ліміту
    if (uploadedFiles.length + files.length > 3) {
      showSnackbar(t("maximum_files_warning"), "error");
      return;
    }

    // Перевірка розміру файлу
    const maxFileSize = 12 * 1024 * 1024; // 12 MB
    const validFiles = uploadedFiles.filter((file) => {
      if (file.size > maxFileSize) {
        showSnackbar(t("file_size_warning", { fileName: file.name }), "error");
        return false;
      }
      return true;
    });

    if (validFiles.length + files.length > 3) {
      showSnackbar(t("maximum_files_warning"), "error");
      return;
    }

    setFiles((prev) => [...prev, ...validFiles]);
  };

  // Функція для видалення файлу
  const handleRemoveFile = (index) => {
    setFiles((prev) => prev.filter((_, i) => i !== index));
  };

  return (
    <div
      className="grid grid-cols-1 md:grid-cols-2 md:p-4 gap-x-4 justify-around bg-cover bg-center rounded-lg mb-4"
      style={{ backgroundImage: `url(/contact_us.png)` }}
    >
      <div className="md:col-start-2 p-4 flex flex-col bg-neutral-900 rounded-lg">
        <p className="text-xl">
          <FaEnvelope className="h-7 w-7 inline -mt-1 me-3" />
          {t("contact_us")}
        </p>
        <hr className="my-4 border-neutral-600" />
        <form
          action=""
          className="flex flex-col flex-1 gap-y-4 justify-between"
        >
          <div className="w-auto flex flex-col sm:flex-row gap-4">
            <input
              type="text"
              placeholder={t("name") + "*"}
              className="w-full p-2 rounded bg-neutral-700 border border-neutral-600 focus:outline-none focus:border-orange-500"
            />
            <input
              type="text"
              placeholder={t("email") + "*"}
              className="w-full p-2 rounded bg-neutral-700 border border-neutral-600 focus:outline-none focus:border-orange-500"
            />
          </div>
          <textarea
            placeholder={t("your_question") + "*"}
            rows={6}
            className="w-full p-2 rounded bg-neutral-700 border border-neutral-600 focus:outline-none focus:border-orange-500"
          ></textarea>

          {/* Кнопка для прикріплення файлів */}
          <label className="w-full flex items-center gap-2 bg-neutral-700 text-white py-2 px-4 rounded-md cursor-pointer hover:bg-neutral-600">
            <FaPaperclip className="h-5 w-5" />
            {t("attach_file")}
            <input
              type="file"
              accept="image/*,.pdf,.doc,.docx,.txt"
              onChange={handleFileUpload}
              className="hidden"
              multiple
            />
          </label>

          {/* Відображення прикріплених файлів */}
          {files.length > 0 && (
            <div className="flex gap-2 sm:gap-4 justify-around">
              {files.map((file, index) => (
                <div
                  key={index}
                  className="relative group w-20 h-20 xl:w-28 xl:h-28"
                >
                  {file.type.startsWith("image/") ? (
                    <img
                      src={URL.createObjectURL(file)}
                      alt="Preview"
                      className="w-full h-full object-cover rounded-md border border-neutral-600"
                    />
                  ) : (
                    <div className="flex flex-col gap-2 items-center justify-center w-full h-full bg-neutral-700 text-white rounded-md border border-neutral-600">
                      <FaFile className="h-10 w-10" />
                      <div className="w-full px-1 flex">
                        <span className="text-xs truncate">{file.name}</span>
                      </div>
                    </div>
                  )}
                  {/* Кнопка для видалення файлу */}
                  <button
                    type="button"
                    className="absolute top-1 right-1 bg-pink-700 text-white rounded-full p-1 opacity-0 group-hover:opacity-100 transition-opacity"
                    onClick={() => handleRemoveFile(index)}
                  >
                    <FaXmark className="h-4 w-4" />
                  </button>
                </div>
              ))}
            </div>
          )}

          <input
            type="submit"
            value={t("send_a_message")}
            className="w-full bg-orange-500 text-white p-2 rounded-md hover:bg-orange-600"
          />
        </form>
      </div>
    </div>
  );
}

export default ContactUs;
