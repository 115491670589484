import React, { useState } from "react";
import { FaDiscord, FaGoogle } from "react-icons/fa6";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { login } from "../../api/authService"; // Імпортуємо функцію

function Auth() {
  const { t } = useTranslation();
  const navigate = useNavigate(); 

  const [nickname, setNickname] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setError(null);
      const token = await login(nickname, password);
      navigate("/")
      // Додати логіку перенаправлення або подальші дії
    } catch (err) {
      setError(err.message);
    }
  };

  return (
    <div className="h-full flex items-center justify-center text-white mb-10">
      <div className="">
        <h2 className="text-2xl font-bold text-center mb-4">{t("auth_login")}</h2>
        <form onSubmit={handleSubmit} className="space-y-4 text-left">
          {/* Поле для пошти */}
          <div>
            <label className="block text-neutral-400 mb-1">{t("auth_login_label")}</label>
            <input
              type="text"
              value={nickname}
              onChange={(e) => setNickname(e.target.value)}
              placeholder={t("auth_login_placeholder")}
              className="w-full p-2 rounded bg-neutral-700 border border-neutral-600 focus:outline-none focus:border-orange-500"
            />
          </div>
          {/* Поле для паролю */}
          <div>
            <label className="block text-neutral-400 mb-1">{t("auth_password_label")}</label>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder={t("auth_password_placeholder")}
              className="w-full p-2 rounded bg-neutral-700 border border-neutral-600 focus:outline-none focus:border-orange-500"
            />
          </div>
          {/* Виведення помилок */}
          {error && <p className="text-red-500 text-center">{error}</p>}
          {/* Кнопка для входу */}
          <button className="w-full bg-orange-500 text-white py-2 rounded-md hover:bg-orange-600">
            {t("auth_login_button")}
          </button>
        </form>

        {/* Роздільна лінія */}
        <div className="text-center my-4 text-neutral-500">{t("auth_or")}</div>

        {/* Кнопки входу через сторонні сервіси */}
        <Link to={"https://api.magatamy.com/authorize/loginDiscord"} className="w-full flex items-center justify-center bg-blue-600 text-white py-2 rounded-md hover:bg-blue-700 mb-2">
          <FaDiscord alt="Discord" className="h-6 w-6 mr-3" />
          {t("auth_login_discord")}
        </Link>
        <Link to={"https://api.magatamy.com/authorize/loginGoogle"} className="w-full flex items-center justify-center bg-red-600 text-white py-2 rounded-md hover:bg-red-700">
          <FaGoogle alt="Google" className="h-6 w-6 mr-3" />
          {t("auth_login_google")}
        </Link>

        {/* Посилання для переходу до реєстрації */}
        <p className="text-neutral-500 mt-4 text-center">
          {t("auth_no_account")}{" "}
          <Link to="/registration" className="text-orange-500 hover:underline">
            {t("auth_register_here")}
          </Link>
        </p>
      </div>
    </div>
  );
}

export default Auth;
