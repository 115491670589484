import React from "react";
import { FaDiscord, FaGoogle } from "react-icons/fa6";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { useEffect } from "react";

function UserProfile({ userData, isLoading }) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    if (
      !localStorage.getItem("jwtToken") ||
      (!isLoading && (!userData || userData === null))
    ) {
      navigate("/auth");
    }
  }, [userData, navigate, isLoading]);

  const logout = () => {
    localStorage.removeItem("jwtToken");
    navigate("/");
  };

  const link = (service) => {
    return `https://api.magatamy.com/authorize/login${service}?jwt=${localStorage.getItem(
      "jwtToken"
    )}`;
  };

  return (
    <div className="">
      {/* Верхня частина профілю */}
      <div className="-mx-4 flex flex-col sm:flex-row items-center gap-6 bg-gradient-to-t from-orange-900 to-orange-900/10 p-6">
        {userData ? (
          <img
            src={userData.avatar_url} // Аватарка користувача
            alt={t("avatar_alt")}
            className="w-28 h-28 rounded-full border-4 border-neutral-700"
          />
        ) : (
          // Заглушка для аватару
          <div className="w-32 h-28 rounded-full bg-neutral-700 border-4 border-neutral-800 animate-pulse" />
        )}
        <div className="text-white text-start w-full flex justify-between">
          <div>
            {userData ? (
              <>
                <h2 className="text-2xl font-semibold">
                  {userData.display_name}
                </h2>
                <p className="text-neutral-300">{userData.email}</p>
              </>
            ) : (
              // Заглушки для тексту
              <>
                <div className="h-6 w-32 bg-neutral-700 rounded animate-pulse mb-2"></div>
                <div className="h-4 w-48 bg-neutral-700 rounded animate-pulse"></div>
              </>
            )}
          </div>
          <div className="flex gap-x-4"></div>
        </div>
      </div>

      {/* Основна частина профілю */}
      <div className="md:grid md:grid-cols-2 lg:grid-cols-5 xl:grid-cols-3 gap-6 my-8">
        {/* Права колонка - Поле для промокоду */}
        <div className="col-span-1 lg:col-span-2 xl:col-span-1 bg-neutral-800 py-2 lg:p-4 rounded-md">
          <h3 className="text-lg font-semibold text-white mb-4">
            {t("activate_promo_code")}
          </h3>
          <input
            type="text"
            placeholder={t("enter_promo_code")}
            className="w-full p-2 rounded-md bg-neutral-700 text-white outline-none"
          />
          <button className="mt-4 w-full bg-orange-500 text-white py-2 rounded-md hover:bg-orange-600">
            {t("activate")}
          </button>
          <hr className="my-6 border-neutral-500" />

          {userData && !userData.discord_link ? (
            <Link
              to={link("Discord")}
              className="w-full flex items-center justify-center bg-blue-600 text-white py-3 rounded-md hover:bg-blue-700 mb-4"
            >
              <FaDiscord alt="Discord" className="h-5 w-5 mr-2" />
              {t("link_discord")}
            </Link>
          ) : (
            <div className="w-full flex items-center justify-center border-2 border-blue-600 text-blue-600 py-3 rounded-md mb-4 cursor-default">
              <FaDiscord alt="Discord" className="h-5 w-5 mr-2" />
              {t("linked_discord")}
            </div>
          )}
          {userData && !userData.google_link ? (
            <Link
              to={link("Google")}
              className="w-full flex items-center justify-center bg-red-600 text-white py-3 rounded-md hover:bg-red-700 mb-4"
            >
              <FaGoogle alt="Google" className="h-5 w-5 mr-2" />
              {t("link_google")}
            </Link>
          ) : (
            <div className="w-full flex items-center justify-center border-2 border-red-600 text-red-600 py-3 rounded-md mb-4 cursor-default">
              <FaGoogle alt="Google" className="h-5 w-5 mr-2" />
              {t("linked_google")}
            </div>
          )}

          <button
            onClick={() => logout()}
            className="w-full py-3 border text-white rounded-md hover:bg-orange-700"
          >
            {t("logout")}
          </button>
        </div>
        {/* Інвентар */}
        {/* <div className="md:col-span-1 lg:col-span-3 xl:col-span-2 bg-neutral-800 py-2 lg:p-4 rounded-md">
          <h3 className="text-lg font-semibold text-white mb-4">
            {t("inventory")}
          </h3>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
            {Array.from({ length: 5 }).map((_, index) => (
              <div key={index} className="">
                {userData ? (
                  <img
                    className="rounded-md"
                    src="/news2.png"
                    alt={t("inventory_item_alt")}
                  />
                ) : (
                  // Заглушка для інвентарю
                  <div className="w-full h-24 bg-neutral-700 rounded-md animate-pulse"></div>
                )}
              </div>
            ))}
          </div>
        </div> */}
      </div>
    </div>
  );
}

export default UserProfile;
