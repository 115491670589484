import axios from "axios";

const API = "https://api.magatamy.com/minecraft"

export const getMain = async () => {
  try {
    const response = await axios.get(`${API}/main`);

    return response.data;
  } catch (error) {
    console.error("getMain error:", error);
    return null;
  }
};


export const getServersInfo = async () => {
  try {
    const response = await axios.get(`${API}/serverInfo`);

    return response.data;
  } catch (error) {
    console.error("getServersInfo error:", error);
    return null;
  }
};