import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  DocumentDuplicateIcon,
} from "@heroicons/react/24/solid";
import ServersPreload from "./ServersPreload"; // Імпорт компоненту ServersPreload
import { useSnackbar } from "../../context/SnackbarContext";
import { getServersInfo } from "../../api/minecraftService";

function Servers() {
  const { t } = useTranslation();
  const { showSnackbar } = useSnackbar();
  const lang = localStorage.getItem("i18nextLng") || "en";
  const [servers, setServers] = useState([]);
  const [isLoading, setIsLoading] = useState(true); // Додано стан завантаження

  useEffect(() => {
    const fetchServersInfo = async () => {
      try {
        const result = await getServersInfo();
        setServers(result);
      } catch (err) {
        console.error(err.message || "Failed to fetch getServersInfo");
      } finally {
        setIsLoading(false); // Завантаження завершено
      }
    };

    fetchServersInfo();
  }, []);

  const copyToClipboard = (text) => {
    if (
      navigator.clipboard &&
      typeof navigator.clipboard.writeText === "function"
    ) {
      navigator.clipboard
        .writeText(text)
        .then(() => showSnackbar("text_copied", "success"))
        .catch(() => showSnackbar("failed_to_copy", "error"));
    } else {
      try {
        const textarea = document.createElement("textarea");
        textarea.value = text;
        textarea.style.position = "fixed";
        textarea.style.opacity = "0";
        document.body.appendChild(textarea);
        textarea.focus();
        textarea.select();
        const successful = document.execCommand("copy");
        document.body.removeChild(textarea);

        if (successful) {
          showSnackbar("text_copied", "success");
        } else {
          throw new Error("Copy failed");
        }
      } catch (error) {
        showSnackbar("failed_to_copy", "error");
      }
    }
  };

  if (isLoading) {
    // Показуємо прелоадери під час завантаження
    return (
      <div className="lg:-mt-16 lg:pt-20 pb-6 lg:px-4 min-h-screen grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-4 lg:gap-x-4 xl:gap-8">
        {Array(3)
          .fill(0)
          .map((_, index) => (
            <ServersPreload key={index} />
          ))}
      </div>
    );
  }

  return (
    <div className="lg:-mt-16 lg:pt-20 pb-6 lg:px-4 min-h-screen grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-4 lg:gap-x-4 xl:gap-8">
      {servers.map((server, index) => (
        <div
          key={server.uuid || index}
          className="h-full lg:scale-95 rounded-lg bg-servers-800 transition-transform transform hover:scale-100 hover:lg:shadow-[0_0_10px_rgba(236,72,153,0.5)] bg-cover bg-center"
          style={{ backgroundImage: `url(/server-bg${index + 1}.jpg)` }}
        >
          <div className="relative z-10 text-white flex flex-col gap-y-20 items-center justify-between h-full bg-black/50 hover:lg:bg-black/60 rounded-lg duration-300">
            <div className="w-full">
              {server.is_online ? (
                <div className="py-2 rounded-t-lg bg-green-800 text-center flex justify-between px-4 items-center">
                  <p>{t("players_online")}: {server.now_online}</p>
                  <p className="text-lg uppercase">
                    {t("online")}{" "}
                    <span className="relative h-4 w-4 ms-2">
                      <span className="animate-ping absolute inline-flex h-4 w-4 inset-0 sm:top-1 rounded-full bg-green-500 opacity-75"></span>
                      <span className="relative inline-flex rounded-full h-4 w-4 bg-green-500"></span>
                    </span>
                  </p>
                </div>
              ) : (
                <div className="py-2 rounded-t-lg bg-pink-900 text-center">
                  <p className="text-lg uppercase">
                    {t("offline")}{" "}
                    <span className="relative inline-flex rounded-full h-4 w-4 bg-red-500 ms-2"></span>
                  </p>
                </div>
              )}
            </div>
            <div className="w-full text-center">
              <h1 className="text-4xl font-bold">{server.name}</h1>
              <p className="text-xs pt-2 px-4">{server.sub_description[lang]}</p>
            </div>
            <div className="w-full">
              <div className="flex flex-col p-4 gap-4">
                <div className="text-sm indent-2">
                  <p>{server.description[lang]}</p>
                </div>
                <p className="text-center">
                  {t("version")}: <span className="font-semibold">{server.version}</span>
                </p>
              </div>
              <button
                onClick={() => copyToClipboard(server.ip)}
                className="py-2 w-full text-lg text-center rounded-b-lg bg-gradient-to-tr from-orange-600 via-pink-600 to-orange-600 bg-gradient-size animate-gradient-move"
              >
                <DocumentDuplicateIcon className="inline h-5 w-5 me-3" />
                {server.ip}
              </button>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default Servers;
