import React from "react";

const SkeletonLoader = () => {
  return (
    <div className="bg-gradient-to-b from-neutral-900 from-40% to-orange-950 p-4 rounded-lg drop-shadow-md relative text-white animate-pulse">
      {/* Знижка */}
      <div className="absolute top-4 right-4 bg-orange-600 h-5 w-12 rounded-full"></div>

      {/* Зображення */}
      <div className="flex justify-center mb-4">
        <div className="w-44 h-44 sm:w-full sm:h-auto lg:w-32 lg:h-32 bg-neutral-700 rounded-lg"></div>
      </div>

      {/* Заголовок */}
      <div className="h-6 bg-neutral-700 rounded mb-2 w-3/4"></div>

      {/* Опис */}
      <div className="h-4 bg-neutral-700 rounded mb-2 w-full"></div>
      <div className="h-4 bg-neutral-700 rounded mb-2 w-5/6"></div>
      <div className="h-4 bg-neutral-700 rounded mb-2 w-full"></div>
      <div className="h-4 bg-neutral-700 rounded mb-2 w-5/6"></div>
      <div className="h-4 bg-neutral-700 rounded mb-2 w-full"></div>
      <div className="h-4 bg-neutral-700 rounded mb-4 w-5/6"></div>

      {/* Ціна та кнопка */}
      <div className="flex justify-between items-center">
        <div className="flex flex-col space-y-2">
          <div className="h-3 w-01 bg-neutral-700 rounded w-1/2"></div>
          <div className="h-5 w-14 bg-orange-600 rounded w-1/3"></div>
        </div>
        <div className="h-10 w-10 bg-orange-600 rounded-lg"></div>
      </div>
    </div>
  );
};

export default SkeletonLoader;
