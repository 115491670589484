import React from "react";
import { FaDiscord, FaTiktok, FaYoutube } from "react-icons/fa6";
import { useTranslation } from "react-i18next";
import ContactUs from "../contactUs/ContactUs";

function AboutUs() {
  const { t } = useTranslation();
  return (
    <div className="">
      <div
        className="bg-cover bg-center -mx-4 p-4 md:p-20 lg:p-6 xl:p-20 h-dvh -mt-16 flex items-center"
        style={{ backgroundImage: `url(/about-us.png)` }}
      >
        <div className="text-center flex flex-col justify-center lg:w-4/5 xl:w-3/4 mx-auto">
          <p className="text-3xl md:text-7xl font-bold mb-8">
            <img
              src="/logo.png"
              className="inline w-20 h-20 -mt-3 me-6"
              alt=""
            />
            Magatamy
          </p>
          <p className="text-sm text-neutral-300 mb-8 leading-normal">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Consectetur
            doloribus sunt odio cumque dolore at perspiciatis corporis adipisci
            quos laboriosam, magni porro obcaecati laudantium inventore dolorem
            ipsum. Illo, laborum eius.
          </p>
          <div className="flex flex-col sm:flex-row gap-2 sm:gap-6 justify-center">
            <a
              target="_blank"
              href="https://discord.gg/Z7VgsBNnxu"
              className="text-center px-8 py-2 rounded-md bg-white/10 hover:bg-white/20 transition-all duration-300 ease-in-out"
            >
              <FaDiscord className="inline w-5 h-5 me-3 -mt-1" /> Discord
            </a>
            <a
              target="_blank"
              href="https://www.youtube.com/@Magatamy"
              className="text-center px-8 py-2 rounded-md bg-white/10 hover:bg-white/20 transition-all duration-300 ease-in-out"
            >
              <FaYoutube className="inline w-5 h-5 me-3 -mt-1" /> Youtube
            </a>
            <a
              target="_blank"
              href="https://www.tiktok.com/@magatamy"
              className="text-center px-8 py-2 rounded-md bg-white/10 hover:bg-white/20 transition-all duration-300 ease-in-out"
            >
              <FaTiktok className="inline w-5 h-5 me-3 -mt-1" /> TikTok
            </a>
          </div>
        </div>
      </div>
      <div className="my-4">
        <ContactUs />
      </div>
    </div>
  );
}

export default AboutUs;
