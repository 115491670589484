import React from 'react';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
} from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Filler, Tooltip, Legend);

const BotChart = ({servers, users}) => {
  // Генерація днів тижня з локалізацією
  const getLocalizedWeekdays = () => {
    const days = [];
    const today = new Date();
    const formatter = new Intl.DateTimeFormat('default', { weekday: 'short' }); // Локалізація коротких назв днів тижня

    for (let i = 6; i >= 0; i--) {
      const day = new Date(today);
      day.setDate(today.getDate() - i);
      days.push(formatter.format(day));
    }

    return days;
  };

  const data = {
    labels: getLocalizedWeekdays(), // Дні тижня з локалізацією
    datasets: [
      {
        label: 'Servers',
        data: servers,
        borderColor: '#db2777',
        backgroundColor: '#831843',
        fill: true,
      },
      {
        label: 'Users',
        data: users,
        borderColor: '#ea580c',
        backgroundColor: '#7c2d12',
        fill: true,
      },
    ],
  };

  const options = {
    responsive: true,
    scales: {
      x: {
        stacked: true,
        ticks: {
          color: '#fff', // Білий колір тексту на осі X
        },
      },
      y: {
        stacked: true,
        beginAtZero: true,
        ticks: {
          color: '#fff', // Білий колір тексту на осі Y
        },
      },
    },
    plugins: {
      legend: {
        display: true,
        position: 'bottom',
        labels: {
          color: '#fff', // Білий колір тексту легенди
        },
      },
      tooltip: {
        titleColor: '#fff', // Білий колір тексту у тултипах
        bodyColor: '#fff', // Білий колір опису у тултипах
        backgroundColor: 'rgba(0, 0, 0, 0.7)', // Тло тултипу
      },
    },
  };

  return <Line data={data} options={options} />;
};

export default BotChart;
